<template>
  <v-row justify="center">
    <v-col lg="8" md="10">
      <div class="document-section">
        <div class="document-section__title">Les Documents téléchargés</div>
        <div class="document-section__list">
          <template v-if="!loader">
            <template v-if="documents.length > 0">
              <DocumentItem
                v-for="(doc, docIndex) in documents"
                :key="doc.id"
                :doc="doc"
                :index="docIndex"
                @deleteDocument="
                  (payloadIndex) => documents.splice(payloadIndex, 1)
                "
                @updateDocument="getDocuments"
              />
            </template>
            <div v-else class="document-section__list--empty">
              Aucun document téléchargé
            </div>
          </template>
          <div v-else class="text-center">
            <v-progress-circular
              :size="50"
              :width="2"
              indeterminate
              color="primary"
            ></v-progress-circular>
          </div>
        </div>
      </div>
      <div class="document-section">
        <div class="document-section__title">
          Les Derniers Devis VEB HABITAT
        </div>
        <div
          v-if="$store.getters['quota/isQuotaFreese']"
          class="document-section__list"
        >
          <DocumentPdfItem
            :loading="loadingGenerateButton"
            :doc="docPdf"
            @downloadPdf="pdfGeneration"
          />
        </div>
      </div>
      <AddFile @addingFilesComplete="addNewFiles" />
    </v-col>
  </v-row>
</template>

<script>
import { DocumentService } from "@/services/documents.service.js";
import offerSumDataMixin from "../mixin/offerSum.js";
export default {
  name: "Documents",
  props: {
    quotaId: {
      type: String,
      required: true,
    },
    quotasList: {
      type: Array,
      default: () => [],
    },
  },
  mixins: [offerSumDataMixin],
  components: {
    DocumentItem: () => import("../DocumentItem/DocumentItem.vue"),
    DocumentPdfItem: () => import("../DocumentItem/DocumentPdfItem.vue"),
    AddFile: () => import("../AddFile/AddFile.vue"),
  },
  data() {
    return {
      documents: [],
      quotas: [],
      loader: true,
    };
  },
  created() {
    this.getDocuments();
  },
  computed: {
    docPdf() {
      return {
        file_type: "pdf",
        filename:
          this.quotasList.find((q) => q.id === +this.quotaId)?.name ||
          "Devis projet",
        local_filename: "",
        size: 10635049,
      };
    },
  },
  watch: {
    quotasList(val) {
      if (val) {
        this.quotas = [...val];
      }
    },
  },
  methods: {
    getDocuments() {
      this.loader = true;
      return DocumentService.getDocuments(this.quotaId)
        .then(({ data }) => {
          this.documents = data;
        })
        .catch(() => {
          this.$store.commit(
            "snackbarModule/SET_ERROR_API",
            this.$t("snackbar.error.base")
          );
        })
        .finally(() => (this.loader = false));
    },
    addNewFiles() {
      this.getDocuments().then(() => {
        this.$store.commit(
          "snackbarModule/SET_SUCCESS_MESSAGE",
          this.$t("snackbar.success.update")
        );
      });
    },
  },
};
</script>

<style src="./Documents.scss" lang="scss" scoped></style>